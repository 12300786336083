<template>
  <div v-if="type != 'Grouped' && type != 'NoValue'" class="mt-1">
    <!--    
    let staticOption="";
  let dynamicOption="";
  if (type!="Grouped"){ 
    // var inputType="static";
    let dynamicChecked="";
    let staticChecked="";
    if (typeof content=="string" && content.indexOf("EVAL:")>-1){
      // inputType="dynamic";
      dynamicChecked="checked='checked'";
    }else {
      staticChecked="checked='checked'";
    }
    staticOption="<input class='inputType' type=radio value='static'"+staticChecked+"></input >";
    dynamicOption="<input class='inputType' type=radio style='margin-left: 5px;' value='dynamic' "+dynamicChecked+"></input >";
  }
    return   "<td class='valueType staticValue'>"+staticOption+"</td><td class='valueType dynamicValue'>"+dynamicOption+"</td>";-->
    <radio
      :fieldAttributes="radiosFieldAttributes"
      v-model="localValue"
      :result="result"
    ></radio>
  </div>
</template>
<script>
import radio from "@/commonComponents/radio.vue";
import { arrayFindInArray } from "@/js/helper.js";
export default {
  components: { radio },
  props: ["result", "fieldAttributes", "checkReadOnly","value"],
  computed: {
    // content() {
    //   return this.result.valueLine.content;
    // },

    type() {
      let valueArray = arrayFindInArray(
        this.result.valueLine.attribute,
        this.result.fields.attribute.associatedOption
      );
      //     tag = valueArray[1];
      let type = valueArray[2];
      //     tag = tag + "*";
      return type;
    },
    radiosFieldAttributes() {
      let fieldAttributes = { ...this.fieldAttributes };
      // fieldAttributes.option = this.fieldAttributes.option;
      // let content = this.content;
      fieldAttributes.value = this.value;

      // if (typeof content == "string" && content.indexOf("EVAL:") > -1) {
      //   fieldAttributes.value = "dynamic";
      // } else {
      //   fieldAttributes.value = "static";
      // }
      return fieldAttributes;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};

// function getValueTypeFields(content,type ){

// }
</script>